import React, { useEffect } from "react";
import './App.css';
import AllRoutes from "./Routes/AllRoutes";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from './Redux/AuthReducer/Action';
import { useToast } from "@chakra-ui/react";

const INACTIVITY_TIMEOUT = 15 * 60 * 1000; 

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    let timeout;

    const resetInactivityTimer = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        dispatch(logout(token, toast, navigate));
      }, INACTIVITY_TIMEOUT);
    };

    // Inactivity detection: listen for user activity
    window.addEventListener("mousemove", resetInactivityTimer);
    window.addEventListener("keydown", resetInactivityTimer);
    window.addEventListener("mousedown", resetInactivityTimer);
    window.addEventListener("touchstart", resetInactivityTimer);

    resetInactivityTimer();

    // Tab-close confirmation
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Required for showing confirmation dialog
    };

    // Auto-logout on tab close
    const handleUnload = () => {
      dispatch(logout(token, toast, navigate));
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    // Cleanup: remove event listeners on component unmount
    return () => {
      clearTimeout(timeout);
      window.removeEventListener("mousemove", resetInactivityTimer);
      window.removeEventListener("keydown", resetInactivityTimer);
      window.removeEventListener("mousedown", resetInactivityTimer);
      window.removeEventListener("touchstart", resetInactivityTimer);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, [dispatch, navigate, token, toast]);

  return (
    <div className="App">
      <AllRoutes />
    </div>
  );
}

export default App;
