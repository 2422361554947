import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Button,
  Input,
  Text,
  Center,
  Divider,
  useColorMode,
  useToast,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import { FaDownload, FaSearch } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createPayroll, DownloadPayroll } from "../../Redux/AppReducer/Action";

const Payroll = () => {
  // const [searchTerm, setSearchTerm] = useState("");
  // const [filteredEmployees, setFilteredEmployees] = useState([]);
  // const [employeesData, setEmployeesData] = useState([]);
  const user = useSelector((store) => store.auth.user);

  const [isEditing, setIsEditing] = useState(false);
  const [grossPayment, setGrossPayment] = useState("200000");
  const [employeeEPF, setEmployeeEPF] = useState("200");
  const [employee, setEmployee] = useState("300");
  const [insurance, setInsurance] = useState("0");
  const [payrollMonth, setPayrollMonth] = useState("");
  const [status, setStatus] = useState("Pending");
  const { colorMode } = useColorMode();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const toast = useToast();

  const [employees, setEmployees] = useState([
    {
      employeeNetPayment: "₹17,25,654.00",
      PaymentDate: 30,
      NumberOfEmployee: "1200",
    },
    // Add more employee data as needed
  ]);

  // const filterEmployees = () => {
  //   const filtered = employeesData.filter((employee) =>
  //     employee.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  //   );
  //   setFilteredEmployees(filtered);
  // };

  // useEffect(() => {
  //   filterEmployees();
  // }, [searchTerm]);

  // const handleEditClick = () => {
  //   setIsEditing(true);
  // };

  // const handleSaveClick = () => {
  //   setIsEditing(false);
  //   // Save the edited values to your backend or perform other actions
  // };

  // const handleInputChange = (value, setValue) => {
  //   // Validate input to allow numbers only
  //   const regex = /^[0-9\b]+$/;
  //   if (value === "" || regex.test(value)) {
  //     setValue(value);
  //   }
  // };

  const handlePayroll = () => {
    if (!payrollMonth) {
      alert("Please select a month and year for the payroll.");
      return;
    }

    dispatch(createPayroll(token, payrollMonth))
      .then(() => {
        toast({
          title: "Payroll Created",
          description: `Payroll for ${new Date(payrollMonth).toLocaleString(
            "default",
            { month: "long", year: "numeric" }
          )} created successfully.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: "Failed to create payroll. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleDownload = () => {
    if (!payrollMonth) {
      alert("Please select a month and year for the payroll download.");
      return;
    }

    dispatch(DownloadPayroll(token, { payrollMonth }))
      .then(() => {
        toast({
          title: "Download Initiated",
          description: `Downloading payroll for ${new Date(
            payrollMonth
          ).toLocaleString("default", { month: "long", year: "numeric" })}.`,
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: "Failed to download payroll. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Box
      p={"8px"}
      mt={"70px"}
      ml={"280px"}
      background={colorMode === "dark" ? "dark.700" : "#fcf7d2"}
    >
      <Flex justifyContent="space-between" mb={8}>
        <Heading textAlign={"left"} fontSize={24} pl={6} pt={5}>
          Welcome!{" "}
          {user?.employeeName == undefined ? (
            <Link to="/login">Login</Link>
          ) : (
            user?.employeeName
          )}
        </Heading>
      </Flex>
      <Divider orientation="horizontal" borderWidth="1px" mb={8} />
      <Flex alignItems="center" mb={8} ml={6}>
        <Heading as="h4" size="md" mb={3}>
          Process Pay Run for Selected Month
        </Heading>
        <Center>
          <Input
            type="month"
            value={payrollMonth}
            onChange={(e) => setPayrollMonth(e.target.value)}
            placeholder="Select Payroll Month"
            maxW="200px"
            ml={5}
            mb={3}
          />
          <Button onClick={handlePayroll} colorScheme="green" ml={5} mb={3}>
            Create Payroll
          </Button>
        </Center>
      </Flex>
      <Box
        p={10}
        borderLeft="4px solid green"
        boxShadow={
          colorMode === "dark"
            ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
            : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
        }
        background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
        borderRadius="md"
      >
        <Flex justifyContent="space-around" alignItems="center" mb={4}>
          <Box
            p={10}
            borderRadius={5}
            boxShadow={
              colorMode === "dark"
                ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
            }
            backdropFilter={"blur(4px)"}
          >
            <Heading as="h3" size="sm" fontWeight={600} mb={2}>
              Employee's Net Pay
            </Heading>
            <Text fontWeight="bold"> ₹17,25,23,654.00</Text>
          </Box>
          <Box
            border={"0px solid red"}
            p={10}
            borderRadius={5}
            boxShadow={
              colorMode === "dark"
                ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
            }
            backdropFilter={"blur(4px)"}
          >
            <Heading as="h3" size="sm" fontWeight={600} mb={2}>
              Payment Date
            </Heading>
            <Text fontWeight="bold">30 APR 2024</Text>
          </Box>
          <Box
            border={"0px solid red"}
            p={10}
            borderRadius={5}
            boxShadow={
              colorMode === "dark"
                ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
            }
            backdropFilter={"blur(4px)"}
          >
            <Heading as="h3" size="sm" fontWeight={600} mb={2}>
              No. of Employees
            </Heading>
            <Text fontWeight="bold">728</Text>
          </Box>
          <Button
            onClick={handleDownload}
            aria-label="Download"
            colorScheme="blue"
            rightIcon={<FaDownload className="download-animation" />}
            mt={2}
            ml={5}
          >
            Download PDF
          </Button>
        </Flex>
      </Box>
      <Box
        mt={10}
        p={10}
        boxShadow={
          colorMode === "dark"
            ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
            : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
        }
        background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
        borderRadius="md"
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Month</Th>
              <Th>Net Payment</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {employees.map((employee, index) => (
              <Tr key={index}>
                <Td>{`₹${grossPayment}`}</Td>
                <Td>{`₹${employeeEPF}`}</Td>
                <Td>{status}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <style>
        {`
          .download-animation {
            animation: downloadAnimation 2s infinite;
          }

          @keyframes downloadAnimation {
            0% { transform: scale(1); }
            50% { transform: scale(1.2); }
            100% { transform: scale(1); }
          }
        `}
      </style>
    </Box>
  );
};

export default Payroll;
