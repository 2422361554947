export const DASHBOARD_DETAILS_REQUEST = "DASHBOARD_DETAILS_REQUEST";
export const DASHBOARD_DETAILS_SUCCESS = "DASHBOARD_DETAILS_SUCCESS";
export const DASHBOARD_DETAILS_FAILURE = "DASHBOARD_DETAILS_FAILURE";

export const ALLEMPLOYEE_REQUEST = "ALLEMPLOYEE_REQUEST";
export const ALLEMPLOYEE_SUCCESS = "ALLEMPLOYEE_SUCCESS";
export const ALLEMPLOYEE_FAILURE = "ALLEMPLOYEE_FAILURE";

export const ALLATTENDANCE_REQUEST = "ALLATTENDANCE_REQUEST";
export const ALLATTENDANCE_SUCCESS = "ALLATTENDANCE_SUCCESS";
export const ALLATTENDANCE_FAILURE = "ALLATTENDANCE_FAILURE";


export const ATTENDANCEOVERVIEW_REQUEST = "ATTENDANCEOVERVIEW_REQUEST";
export const ATTENDANCEOVERVIEW_SUCCESS = "ATTENDANCEOVERVIEW_SUCCESS";
export const ATTENDANCEOVERVIEW_FAILURE = "ATTENDANCEOVERVIEW_FAILURE";


export const ATTENDANCELOGS_REQUEST = "ATTENDANCELOGS_REQUEST";
export const ATTENDANCELOGS_SUCCESS = "ATTENDANCELOGS_SUCCESS";
export const ATTENDANCELOGS_FAILURE = "ATTENDANCELOGS_FAILURE";


export const GETREVIEWATTENDANCE_REQUEST = "GETREVIEWATTENDANCE_REQUEST";
export const GETREVIEWATTENDANCE_SUCCESS = "GETREVIEWATTENDANCE_SUCCESS";
export const GETREVIEWATTENDANCE_FAILURE = "GETREVIEWATTENDANCE_FAILURE";


export const APPROVEDATTENDANCE_REQUEST = "APPROVEDATTENDANCE_REQUEST";
export const APPROVEDATTENDANCE_SUCCESS = "APPROVEDATTENDANCE_SUCCESS";
export const APPROVEDATTENDANCE_FAILURE = "APPROVEDATTENDANCE_FAILURE";


export const DOWNLOADATTENDANCE_REQUEST = "DOWNLOADATTENDANCE_REQUEST";
export const DOWNLOADATTENDANCE_SUCCESS = "DOWNLOADATTENDANCE_SUCCESS";
export const DOWNLOADATTENDANCE_FAILURE = "DOWNLOADATTENDANCE_FAILURE";



export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";


export const SEARCHEMPLOYEE_REQUEST = "SEARCHEMPLOYEE_REQUEST";
export const SEARCHEMPLOYEE_SUCCESS = "SEARCHEMPLOYEE_SUCCESS";
export const SEARCHEMPLOYEE_FAILURE = "SEARCHEMPLOYEE_FAILURE";


export const UPDATEEMPLOYEE_REQUEST = "UPDATEEMPLOYEE_REQUEST";
export const UPDATEEMPLOYEE_SUCCESS = "UPDATEEMPLOYEE_SUCCESS";
export const UPDATEEMPLOYEE_FAILURE = "UPDATEEMPLOYEE_FAILURE";


export const DELETEEMPLOYEE_REQUEST = "DELETEEMPLOYEE_REQUEST";
export const DELETEEMPLOYEE_SUCCESS = "DELETEEMPLOYEE_SUCCESS";
export const DELETEEMPLOYEE_FAILURE = "DELETEEMPLOYEE_FAILURE";

export const CHECKINEMPLOYEE_REQUEST = "CHECKINEMPLOYEE_REQUEST";
export const CHECKINEMPLOYEE_SUCCESS = "CHECKINEMPLOYEE_SUCCESS";
export const CHECKINEMPLOYEE_FAILURE = "CHECKINEMPLOYEE_FAILURE";


export const CHECKOUTEMPLOYEE_REQUEST = "CHECKOUTEMPLOYEE_REQUEST";
export const CHECKOUTEMPLOYEE_SUCCESS = "CHECKOUTEMPLOYEE_SUCCESS";
export const CHECKOUTEMPLOYEE_FAILURE = "CHECKOUTEMPLOYEE_FAILURE";

export const CREATELEAVE_REQUEST = "CREATELEAVE_REQUEST";
export const CREATELEAVE_SUCCESS = "CREATELEAVE_SUCCESS";
export const CREATELEAVE_FAILURE = "CREATELEAVE_FAILURE";

export const GETUSERLEAVE_REQUEST = "GETUSERLEAVE_REQUEST";
export const GETUSERLEAVE_SUCCESS = "GETUSERLEAVE_SUCCESS";
export const GETUSERLEAVE_FAILURE = "GETUSERLEAVE_FAILURE";


export const GETSTATUS_REQUEST = "GETSTATUS_REQUEST";
export const GETSTATUS_SUCCESS = "GETSTATUS_SUCCESS";
export const GETSTATUS_FAILURE = "GETSTATUS_FAILURE";


export const GETLEAVEREQUEST_REQUEST = "GETLEAVEREQUEST_REQUEST";
export const GETLEAVEREQUEST_SUCCESS = "GETLEAVEREQUEST_SUCCESS";
export const GETLEAVEREQUEST_FAILURE = "GETLEAVEREQUEST_FAILURE";

export const UPDATESTATUS_REQUEST = "UPDATESTATUS_REQUEST";
export const UPDATESTATUS_SUCCESS = "UPDATESTATUS_SUCCESS";
export const UPDATESTATUS_FAILURE = "UPDATESTATUS_FAILURE";


export const CREATEEVENTS_REQUEST = "CREATEEVENTS_REQUEST";
export const CREATEEVENTS_SUCCESS = "CREATEEVENTS_SUCCESS";
export const CREATEEVENTS_FAILURE = "CREATEEVENTS_FAILURE";

export const UPCOMINGEVENT_REQUEST = "UPCOMINGEVENT_REQUEST";
export const UPCOMINGEVENT_SUCCESS = "UPCOMINGEVENT_SUCCESS";
export const UPCOMINGEVENT_FAILURE = "UPCOMINGEVENT_FAILURE";


export const GETALLHIKES_REQUEST = "GETALLHIKES_REQUEST";
export const GETALLHIKES_SUCCESS = "GETALLHIKES_SUCCESS";
export const GETALLHIKES_FAILURE = "GETALLHIKES_FAILURE";

export const CREATEHIKE_REQUEST = "CREATEHIKE_REQUEST";
export const CREATEHIKE_SUCCESS = "CREATEHIKE_SUCCESS";
export const CREATEHIKE_FAILURE = "CREATEHIKE_FAILURE";

export const GETUSERHIKE_REQUEST = "GETUSERHIKE_REQUEST";
export const GETUSERHIKE_SUCCESS = "GETUSERHIKE_SUCCESS";
export const GETUSERHIKE_FAILURE = "GETUSERHIKE_FAILURE";

export const RESIGN_REQUEST = "RESIGN_REQUEST";
export const RESIGN_SUCCESS = "RESIGN_SUCCESS";
export const RESIGN_FAILURE = "RESIGN_FAILURE";

export const GETLEAVEDETAILS_REQUEST = "GETLEAVEDETAILS_REQUEST";
export const GETLEAVEDETAILS_SUCCESS = "GETLEAVEDETAILS_SUCCESS";
export const GETLEAVEDETAILS_FAILURE = "GETLEAVEDETAILS_FAILURE";

export const CREATEREWARDS_REQUEST = "CREATEREWARDS_REQUEST";
export const CREATEREWARDS_SUCCESS = "CREATEREWARDS_SUCCESS";
export const CREATEREWARDS_FAILURE = "CREATEREWARDS_FAILURE";

export const GETAWARDSANDREWARDS_REQUEST = "GETAWARDSANDREWARDS_REQUEST";
export const GETAWARDSANDREWARDS_SUCCESS = "GETAWARDSANDREWARDS_SUCCESS";
export const GETAWARDSANDREWARDS_FAILURE = "GETAWARDSANDREWARDS_FAILURE";


export const GETREWARD_REQUEST = "GETREWARD_REQUEST";
export const GETREWARD_SUCCESS = "GETREWARD_SUCCESS";
export const GETREWARD_FAILURE = "GETREWARD_FAILURE";


export const POSTCOMPLAINT_REQUEST = "POSTCOMPLAINT_REQUEST";
export const POSTCOMPLAINT_SUCCESS = "POSTCOMPLAINT_SUCCESS";
export const POSTCOMPLAINT_FAILURE = "POSTCOMPLAINT_FAILURE";


export const GETCOMPLAINT_REQUEST = "GETCOMPLAINT_REQUEST";
export const GETCOMPLAINT_SUCCESS = "GETCOMPLAINT_SUCCESS";
export const GETCOMPLAINT_FAILURE = "GETCOMPLAINT_FAILURE";

export const GETUSERCOMPLAINT_REQUEST = "GETUSERCOMPLAINT_REQUEST";
export const GETUSERCOMPLAINT_SUCCESS = "GETUSERCOMPLAINT_SUCCESS";
export const GETUSERCOMPLAINT_FAILURE = "GETUSERCOMPLAINT_FAILURE";


export const GETSTATISTICS_REQUEST = "GETSTATISTICS_REQUEST";
export const GETSTATISTICS_SUCCESS = "GETSTATISTICS_SUCCESS";
export const GETSTATISTICS_FAILURE = "GETSTATISTICS_FAILURE";


export const GETUPDATESTATUS_REQUEST = "GETUPDATESTATUS_REQUEST";
export const GETUPDATESTATUS_SUCCESS = "GETUPDATESTATUS_SUCCESS";
export const GETUPDATESTATUS_FAILURE = "GETUPDATESTATUS_FAILURE";


export const GETTICKETS_REQUEST = "GETTICKETS_REQUEST";
export const GETTICKETS_SUCCESS = "GETTICKETS_SUCCESS";
export const GETTICKETS_FAILURE = "GETTICKETS_FAILURE";


export const GETALLTICKETS_REQUEST = "GETALLTICKETS_REQUEST";
export const GETALLTICKETS_SUCCESS = "GETALLTICKETS_SUCCESS";
export const GETALLTICKETS_FAILURE = "GETALLTICKETS_FAILURE";


export const GETUSERTICKETS_REQUEST = "GETUSERTICKETS_REQUEST";
export const GETUSERTICKETS_SUCCESS = "GETUSERTICKETS_SUCCESS";
export const GETUSERTICKETS_FAILURE = "GETUSERTICKETS_FAILURE";


export const CREATETICKETS_REQUEST = "CREATETICKETS_REQUEST";
export const CREATETICKETS_SUCCESS = "CREATETICKETS_SUCCESS";
export const CREATETICKETS_FAILURE = "CREATETICKETS_FAILURE";

export const EMPLOYEESETUP2FA_REQUEST = "EMPLOYEESETUP2FA_REQUEST";
export const EMPLOYEESETUP2FA_SUCCESS = "EMPLOYEESETUP2FA_SUCCESS";
export const EMPLOYEESETUP2FA_FAILURE = "EMPLOYEESETUP2FA_FAILURE";


export const GETEMPINFO_REQUEST = "GETEMPINFO_REQUEST";
export const GETEMPINFO_SUCCESS = "GETEMPINFO_SUCCESS";
export const GETEMPINFO_FAILURE = "GETEMPINFO_FAILURE";


export const POSTUPLOADDOCUMENT_REQUEST = "POSTUPLOADDOCUMENT_REQUEST";
export const POSTUPLOADDOCUMENT_SUCCESS = "POSTUPLOADDOCUMENT_SUCCESS";
export const POSTUPLOADDOCUMENT_FAILURE = "POSTUPLOADDOCUMENT_FAILURE";


export const USERCREATEREFERRAL_REQUEST = "USERCREATEREFERRAL_REQUEST";
export const USERCREATEREFERRAL_SUCCESS = "USERCREATEREFERRAL_SUCCESS";
export const USERCREATEREFERRAL_FAILURE = "USERCREATEREFERRAL_FAILURE";


export const GETALLREFERRAL_REQUEST = "GETALLREFERRAL_REQUEST";
export const GETALLREFERRAL_SUCCESS = "GETALLREFERRAL_SUCCESS";
export const GETALLREFERRAL_FAILURE = "GETALLREFERRAL_FAILURE";


export const USERREIMBURSEMENT_REQUEST = "USERREIMBURSEMENT_REQUEST";
export const USERREIMBURSEMENT_SUCCESS = "USERREIMBURSEMENT_SUCCESS";
export const USERREIMBURSEMENT_FAILURE = "USERREIMBURSEMENT_FAILURE";


export const GETALLREIMBURSEMENT_REQUEST = "GETALLREIMBURSEMENT_REQUEST";
export const GETALLREIMBURSEMENT_SUCCESS = "GETALLREIMBURSEMENT_SUCCESS";
export const GETALLREIMBURSEMENT_FAILURE = "GETALLREIMBURSEMENT_FAILURE";


export const APPROVEDREIMBURSEMENT_REQUEST = "APPROVEDREIMBURSEMENT_REQUEST";
export const APPROVEDREIMBURSEMENT_SUCCESS = "APPROVEDREIMBURSEMENT_SUCCESS";
export const APPROVEDREIMBURSEMENT_FAILURE = "APPROVEDREIMBURSEMENT_FAILURE";


export const GETPAYSLIP_REQUEST = "GETPAYSLIP_REQUEST";
export const GETPAYSLIP_SUCCESS = "GETPAYSLIP_SUCCESS";
export const GETPAYSLIP_FAILURE = "GETPAYSLIP_FAILURE";


export const CREATEPAYROLL_REQUEST = "CREATEPAYROLL_REQUEST";
export const CREATEPAYROLL_SUCCESS = "CREATEPAYROLL_SUCCESS";
export const CREATEPAYROLL_FAILURE = "CREATEPAYROLL_FAILURE";


export const DOWNLOADPAYROLL_REQUEST = "DOWNLOADPAYROLL_REQUEST";
export const DOWNLOADPAYROLL_SUCCESS = "DOWNLOADPAYROLL_SUCCESS";
export const DOWNLOADPAYROLL_FAILURE = "DOWNLOADPAYROLL_FAILURE";
