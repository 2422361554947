import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { UserCreateReferral } from "../../Redux/AppReducer/Action";

const Referral = () => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [formData, setFormData] = useState({
    name: "",
    contactNumber: "",
    position: "",
    email: "",
    referredBy: "",
    resume: null,
  });
  const [emailError, setEmailError] = useState("");
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  const validateInput = (name, value) => {
    const rules = {
      name: /^[a-zA-Z\s]{1,40}$/,
      email: /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,6}$/,
      position: /^[a-zA-Z\s]{1,40}$/,
      referredBy: /^[a-zA-Z\s]{1,40}$/,
      contactNumber: /^\d{10,13}$/,
    };
    return rules[name]?.test(value) || false;
  };
  const sanitizeInput = (value) =>
    value.replace(/[^a-zA-Z0-9\s]/g, "").slice(0, 40);
  const validateEmail = (email) =>
    /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,6}$/.test(email.trim());
  const validateContactNumber = (number) => /^\d{10,13}$/.test(number);

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "contactNumber") {
      const sanitizedValue = value.replace(/\D/g, "").slice(0, 13); // Allow only digits, max length 13
      setFormData({ ...formData, [name]: sanitizedValue });
      return;
    }
  
    const sanitizedValue =
      name !== "email"
        ? sanitizeInput(value)
        : value.trim(); 
  
    setFormData({ ...formData, [name]: sanitizedValue });
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({ ...prevData, email: value }));
    if (!validateEmail(value)) {
      setEmailError("Invalid email.");
    } else {
      setEmailError("");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        toast({
          title: "File size should be less than 5MB",
          status: "error",
          duration: 3000,
        });
        setFormData((prevData) => ({ ...prevData, resume: null }));
        fileInputRef.current.value = null;
        return;
      }

      if (file.type !== "application/pdf") {
        toast({
          title: "Only PDF files are allowed",
          status: "error",
          duration: 3000,
        });
        setFormData((prevData) => ({ ...prevData, resume: null }));
        fileInputRef.current.value = null;
        return;
      }

      setFormData((prevData) => ({ ...prevData, resume: file }));
    }
  };

  const submitApplication = () => {
    const { position, contactNumber, name, email, resume, referredBy } =
      formData;

    if (
      !position ||
      !contactNumber ||
      !name ||
      !email ||
      !resume ||
      !referredBy
    ) {
      toast({
        title: "Please fill in all required fields",
        status: "error",
        duration: 3000,
      });
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Invalid email.");
      return;
    }

    if (!validateContactNumber(contactNumber)) {
      toast({
        title: "Invalid contact number. It should contain 10–13 digits.",
        status: "error",
        duration: 3000,
      });
      return;
    }

    const submissionData = new FormData();
    Object.keys(formData).forEach((key) => {
      submissionData.append(key, formData[key]);
    });

    dispatch(UserCreateReferral(token, submissionData))
      .then(() => {
        toast({
          title: "Application submitted successfully!",
          status: "success",
          duration: 3000,
        });
        setFormData({
          name: "",
          contactNumber: "",
          position: "",
          email: "",
          referredBy: "",
          resume: null,
        });
        fileInputRef.current.value = null;
      })
      .catch(() =>
        toast({
          title: "Failed to submit application",
          status: "error",
          duration: 3000,
        })
      );
  };

  return (
    <Box
      mt="70px"
      ml="280px"
      background={colorMode === "dark" ? "dark" : "#fcf7d2"}
      borderWidth="1px"
    >
      <Box
        borderWidth="1px"
        borderRadius="md"
        p={2}
        mt={"20px"}
        ml={"250px"}
        mr={4}
        boxShadow={
          colorMode === "dark"
            ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
            : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
        }
        width={"50%"}
        pt={"10px"}
        mb={4}
        pl={"17px"}
        border="2px solid #e04141"
        bg={colorMode === "dark" ? "dark.700" : "#fae8e1"}
      >
        <FormLabel
          fontSize={25}
          mt={1}
          mb={4}
          pl={"125px"}
          position="center"
          color={colorMode === "dark" ? "white" : "black"}
        >
          Applicant Details
        </FormLabel>
        <FormControl
          mb={2}
          borderColor="teal.200"
          _focus={{ borderColor: "teal.500" }}
        >
          <FormLabel pl={"10px"}>Name</FormLabel>
          <Input
            type="text"
            name="name"
            borderColor={"#e04141"}
            placeholder="Enter your name"
            value={formData.name}
            onChange={handleChange}
            _placeholder={{ color: "gray.500" }}
            autoComplete="off"
            maxLength={40}
          />
        </FormControl>

        <FormControl
          mb={2}
          borderColor="teal.200"
          _focus={{ borderColor: "teal.500" }}
        >
          <FormLabel pl={"10px"}>Email</FormLabel>
          <Input
            type="email"
            name="email"
            placeholder="Enter your email"
            borderColor={"#e04141"}
            value={formData.email}
            onChange={handleEmailChange}
            isInvalid={emailError !== ""}
            _placeholder={{ color: "gray.500" }}
            autoComplete="off"
            maxLength={50}
          />
          {emailError && (
            <Box color="red.500" fontSize="sm" mt={1}>
              {emailError}
            </Box>
          )}
        </FormControl>

        <FormControl
          mb={2}
          borderColor="teal.200"
          _focus={{ borderColor: "teal.500" }}
        >
          <FormLabel pl={"10px"}>Position</FormLabel>
          <Input
            type="text"
            name="position"
            placeholder="Enter job title"
            borderColor={"#e04141"}
            value={formData.position}
            onChange={handleChange}
            _placeholder={{ color: "gray.500" }}
            autoComplete="off"
            maxLength={40}
          />
        </FormControl>

        <FormControl
          mb={2}
          borderColor="teal.200"
          _focus={{ borderColor: "teal.500" }}
        >
          <FormLabel pl={"10px"}>ReferredBy</FormLabel>
          <Input
            type="text"
            name="referredBy"
            placeholder="Enter job title"
            borderColor={"#e04141"}
            value={formData.referredBy}
            onChange={handleChange}
            _placeholder={{ color: "gray.500" }}
            autoComplete="off"
            maxLength={40}
          />
        </FormControl>

        <FormControl
          mb={4}
          borderColor="teal.200"
          _focus={{ borderColor: "teal.500" }}
        >
          <FormLabel pl={"10px"}>Contact Number</FormLabel>
          <Input
            placeholder="Enter contact Number"
            name="contactNumber"
            borderColor={"#e04141"}
            value={formData.contactNumber}
            type="tel"
            onChange={handleChange}
            rows={2}
            _placeholder={{ color: "gray.500" }}
            autoComplete="off"
            maxLength={13}
          />
        </FormControl>

        <FormControl
          mb={2}
          borderColor={"#e04141"}
          _focus={{ borderColor: "teal.500" }}
          id="resume"
        >
          <FormLabel pl={"10px"}>Resume</FormLabel>
          <Input
            pt={1}
            borderColor={"#e04141"}
            type="file"
            name="resume"
            ref={fileInputRef}
            accept=".pdf"
            onChange={handleFileChange}
            _placeholder={{ color: "gray.500" }}
            autoComplete="off"
          />
        </FormControl>


        <Button
          color={"#FECE01"}
          background={"#e04141"}
          onClick={submitApplication}
          pl={"10px"}
        >
          Submit Application
        </Button>
      </Box>
    </Box>
  );
};

export default Referral;
